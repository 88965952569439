import React from 'react';
import {Typography, Row, Col} from 'antd';
import {formatDate, generateUrlParameters, toVND} from '~utils/helper';
import ItemPlaceholder from './Placeholder';
import useFetch from '~hooks/useFetch';

const {Text} = Typography;

export default function WalletHistory({isFetch = true}) {
  const paramRequest = {
    'sort.prop_name': 'CreatedDate',
    'sort.direction': 'Desc',
  };
  const {status, data = {}} = useFetch(
    !isFetch ? '' : `${process.env.GATSBY_API_URL}/v1/wallet-history/list?${generateUrlParameters(paramRequest)}`
  );
  const isLoading = status === 'fetching';

  const {items = []} = data;
  const isShowList = !isLoading && items.length > 0;

  return (
    <>
      <Typography.Title style={{padding: '14px 16px 0'}} level={5}>
        Lịch sử ví
      </Typography.Title>
      {isLoading &&
        Array(5)
          .fill()
          .map((_, index) => <ItemPlaceholder key={index} />)}
      {isShowList &&
        items.map(({id, moneyVolatility, note, createdDate, currentBalance}, index) => (
          <div key={id}>
            <Row
              style={{
                backgroundColor: '#fff',
                padding: '12px 16px',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '18px',
                marginBlock: '20px',
              }}
            >
              <Col span={24}>
                <Row
                  justify='space-between'
                  align='middle'
                  style={{
                    marginBottom: '10px',
                  }}
                >
                  <Col>
                    <Text
                      strong
                      style={{
                        color: moneyVolatility < 0 ? '#96A2B2' : '#EF9F84',
                        lineHeight: '24px',
                      }}
                    >
                      {moneyVolatility < 0 ? `- ${toVND(moneyVolatility * -1)}` : `+ ${toVND(moneyVolatility)}`}
                    </Text>
                  </Col>
                  <Col>
                    <Text
                      style={{
                        fontSize: '13px',
                        lineHeight: '24px',
                        color: '#828282',
                      }}
                    >
                      {`(${formatDate(createdDate, 'MMM DD, yyyy')})`}
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify='space-between' align='middle'>
                  <Col>
                    <Text
                      style={{
                        color: '#000',
                        fontWeight: 500,
                        lineHeight: '30px',
                        fontSize: '14px',
                        padding: '6px 10px',
                        borderRadius: '4px',
                        border: '1px dashed #96a2b2',
                      }}
                      ellipsis={1}
                    >
                      {note}
                    </Text>
                  </Col>
                  <Col flex>
                    <Text
                      style={{
                        color: '#000',
                        fontWeight: 500,
                        lineHeight: '30px',
                        fontSize: '14px',
                        padding: '6px 0px',
                      }}
                    >
                      {`Số dư ví: ${toVND(currentBalance ?? 0)}`}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
            {index < items.length - 1 && <div style={{borderBottom: 'solid 1px #d8d8d8'}} />}
          </div>
        ))}
    </>
  );
}
