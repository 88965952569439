import React from 'react';
import {Row, Col, Skeleton, Typography} from 'antd';

const {Text} = Typography;

export default function ItemPlaceholder() {
  return (
    <Row
      style={{
        backgroundColor: '#fff',
        padding: '12px 16px',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '18px',
        marginBlock: '10px',
      }}
    >
      <Col span={24}>
        <Row
          justify='space-between'
          align='middle'
          style={{
            marginBottom: '10px',
          }}
        >
          <Col>
            <Text
              strong
              style={{
                color: '#EF9F84',
                lineHeight: '24px',
              }}
            >
              <Skeleton.Input size='small' />
            </Text>
          </Col>
          <Col>
            <Text
              style={{
                fontSize: '13px',
                lineHeight: '24px',
                color: '#828282',
              }}
            >
              <Skeleton.Input size='small' />
            </Text>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row justify='space-between' align='middle'>
          <Col>
            <Text
              style={{
                color: '#000',
                fontWeight: 500,
                lineHeight: '30px',
                fontSize: '14px',
                padding: '6px 10px',
                borderRadius: '4px',
                border: '1px dashed #96a2b2',
              }}
              ellipsis={1}
            >
              <Skeleton.Input size='small' />
            </Text>
          </Col>
          <Col flex>
            <Text
              style={{
                color: '#000',
                fontWeight: 500,
                lineHeight: '30px',
                fontSize: '14px',
                padding: '6px 0px',
              }}
            >
              <Skeleton.Input size='small' />
            </Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
