import React from 'react';
import useFetch from '../../../hooks/useFetch';
import {List, Typography, Skeleton} from 'antd';
import MyWalletIcon from '~components/icons/my-wallet';
import {toWalletCoin} from '~utils/helper';
import _isEmpty from 'lodash/isEmpty';
import WalletHistory from './wallet-history';

// import * as styles from './styles.module.scss';

const {Text} = Typography;
const DEFAULT_BALANCE = 0;

const MyWallet = () => {
  const {status, data} = useFetch(`${process.env.GATSBY_API_URL}/v1/wallet`);

  const isLoading = status === 'fetching';
  const isUnknownWallet = status === 'fetched' && _isEmpty(data);

  const balanceNumber = !isUnknownWallet ? data?.availableBalance ?? DEFAULT_BALANCE : DEFAULT_BALANCE;
  const membershipNumber = !isUnknownWallet ? data?.membershipBalance ?? DEFAULT_BALANCE : DEFAULT_BALANCE;
  const showBalance = isLoading ? <Skeleton.Input style={{height: '30px'}} /> : toWalletCoin(balanceNumber);
  const showMembershipCoin = isLoading ? <Skeleton.Input style={{height: '30px'}} /> : toWalletCoin(membershipNumber);
  const isFetchHistory = status === 'fetched' && !_isEmpty(data);

  return (
    <>
      <div
        style={{
          paddingBottom: '80px',
          paddingTop: '10px',
          minHeight: 'calc(100vh - 130px)',
        }}
      >
        <List
          itemLayout='horizontal'
          dataSource={data ? [data] : [[]]}
          renderItem={() => (
            <List.Item
              style={{
                margin: '0',
                padding: '0',
              }}
            >
              <List.Item.Meta
                avatar={
                  <div
                    style={{
                      border: '1px solid #EF9F84',
                      padding: '14px',
                      borderRadius: '50%',
                      display: 'flex',
                    }}
                  >
                    <MyWalletIcon w={28} h={25} fill='#EF9F84' />
                  </div>
                }
                title={
                  <Text
                    style={{
                      fontSize: '16px',
                      lineHeight: '24px',
                      fontWeight: '600',
                      color: '#000',
                      marginBottom: '6px',
                    }}
                  >
                    Ví
                  </Text>
                }
                description={
                  <Text
                    style={{
                      fontSize: '24px',
                      color: '#EF9F84',
                      fontWeight: '500',
                      lineHeight: '24px',
                    }}
                  >
                    {showBalance}
                  </Text>
                }
              />
              {data?.membershipBalance && (
                <List.Item.Meta
                  avatar={
                    <div
                      style={{
                        border: '1px solid #EF9F84',
                        padding: '14px',
                        borderRadius: '50%',
                        display: 'flex',
                      }}
                    >
                      <MyWalletIcon w={28} h={25} fill='#EF9F84' />
                    </div>
                  }
                  title={
                    <Text
                      style={{
                        fontSize: '16px',
                        lineHeight: '24px',
                        fontWeight: '600',
                        color: '#000',
                        marginBottom: '6px',
                      }}
                    >
                      Gói thành viên
                    </Text>
                  }
                  description={
                    <Text
                      style={{
                        fontSize: '24px',
                        color: '#EF9F84',
                        fontWeight: '500',
                        lineHeight: '24px',
                      }}
                    >
                      {showMembershipCoin}
                    </Text>
                  }
                />
              )}
            </List.Item>
          )}
          style={{backgroundColor: '#fff', padding: '16px'}}
        />
        <WalletHistory isFetch={isFetchHistory} />
      </div>
    </>
  );
};

export default MyWallet;
